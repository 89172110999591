import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

import Layout from 'components/layout/Layout'
import SEO from 'components/shared/SEO'
import Column from 'components/home/Column'

const FullHeightRow = styled.div`
  min-height: calc(100vh - 115px);

  @media screen and (max-width: 991px) {
    min-height: calc(100vh - 115px);
  }

  @media screen and (max-width: 575px) {
    min-height: calc(100vh - 64px);
  }

  @media screen and (max-width: 517px) {
    min-height: calc(100vh - 80px);
  }

  @media screen and (max-width: 400px) {
    min-height: calc(100vh - 74px);
  }

  @media screen and (max-width: 358px) {
    min-height: calc(100vh - 68px);
  }
`

const ColumnLeft = styled.div`
  /* background-color: ${({ theme }) => theme.colors.secondary}; */
`

const ColumnRight = styled.div`
  /* background-color: ${({ theme }) => theme.colors.primary}; */
`

interface PageProps {
  data: GatsbyTypes.homePageQuery
}

function Home({ data: { page } }: PageProps) {
  if (!page) return null
  if (!page.templateHome) return null
  // if (!page.templateHome.pluriform) return null;
  // if (!page.templateHome.ecd) return null;

  return (
    <Layout type="home" hideFooter>
      <SEO seo={page?.seo} />

      <FullHeightRow className="row no-gutters">
        <ColumnLeft className="col-md-6 position-relative px-5">
          <Column
            bubbleType="left"
            background={page.templateHome.pluriform?.background}
            title={page.templateHome.pluriform?.title}
            icon={page.templateHome.pluriform?.icon}
            image={page.templateHome.pluriform?.image}
            usps={page.templateHome.pluriform?.usps}
            link={page.templateHome.pluriform?.link}
          />
        </ColumnLeft>
        <ColumnRight className="col-md-6 position-relative px-5">
          <Column
            bubbleType="right"
            background={page.templateHome.ecd?.background}
            title={page.templateHome.ecd?.title}
            icon={page.templateHome.ecd?.icon}
            image={page.templateHome.ecd?.image}
            usps={page.templateHome.ecd?.usps}
            link={page.templateHome.ecd?.link}
          />
        </ColumnRight>
      </FullHeightRow>
    </Layout>
  )
}

export const pageQuery = graphql`
  query homePage($id: String) {
    page: wpPage(id: { eq: $id }) {
      id
      databaseId
      templateHome {
        pluriform {
          title
          icon {
            localFile {
              publicURL
            }
          }
          link {
            title
            url
          }
          usps {
            link {
              title
              url
            }
            subtitle
            icon {
              localFile {
                publicURL
              }
            }
          }
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 350
                  height: 410
                  quality: 100
                  layout: CONSTRAINED
                  placeholder: NONE
                )
              }
            }
          }
          background {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 1920
                  transformOptions: { grayscale: true }
                  quality: 100
                )
              }
            }
          }
        }

        ecd {
          title
          icon {
            localFile {
              publicURL
            }
          }
          slogan
          link {
            title
            url
          }
          usps {
            link {
              title
              url
            }
          }
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 350
                  height: 410
                  quality: 100
                  layout: CONSTRAINED
                  placeholder: NONE
                )
              }
            }
          }
          background {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 1920
                  transformOptions: { grayscale: true }
                  quality: 100
                )
              }
            }
          }
        }
      }
      seo {
        canonical
        cornerstone
        focuskw
        metaDesc
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        opengraphModifiedTime
        opengraphPublisher
        opengraphPublishedTime
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        title
        twitterDescription
        twitterTitle
        opengraphImage {
          sourceUrl
        }
        twitterImage {
          sourceUrl
        }
      }
    }
  }
`

export default Home
