import { Link } from 'gatsby'
import React from 'react'
import styled, { css } from 'styled-components'

import ChatCloudRight from 'img/chat-cloud-right.inline.svg'
import ChatCloudLeft from 'img/chat-cloud-left.inline.svg'
import ArrowRight from 'img/arrow-right.inline.svg'
import BackgroundImage from 'components/shared/BackgroundImage'
import Image from 'components/shared/Image'
import parse from 'html-react-parser'

const ColumnContainer = styled.div`
  max-width: 674px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 30px;
`

const TextBubble = styled.div`
  width: 600px;
  height: 285px;
  margin-left: auto;
  margin-right: auto;

  @media screen and (max-height: 790px) {
    width: 400px;
    height: 240px;
  }

  @media screen and (max-width: 1400px) {
    width: 400px;
    height: 240px;
  }

  @media screen and (max-width: 991px) {
    width: 300px;
    height: 160px;
  }

  @media screen and (max-width: 349px) {
    width: 250px;
    height: 120px;
  }
`

const ButtonOutsideBubble = styled.div<{ bubbleType: string }>`
  @media (min-width: 992px) {
    position: absolute;
    bottom: 30px;
    z-index: 1;
  }

  @media (max-width: 991px) {
    justify-content: center;
  }

  ${(props) =>
    props.bubbleType === 'left' &&
    css`
      justify-content: flex-start;

      @media (min-width: 1250px) {
        padding-left: 100px;
      }

      @media (max-width: 1249px) {
        padding-left: 50px;
      }

      @media (max-width: 991px) {
        padding-left: 0;
      }
    `};

  ${(props) =>
    props.bubbleType === 'right' &&
    css`
      justify-content: flex-end;

      @media (min-width: 1450px) {
        padding-right: 200px;
      }

      @media (max-width: 1449px) {
        padding-right: 100px;
      }

      @media (max-width: 1399px) {
        padding-right: 75px;
      }

      @media (max-width: 991px) {
        padding-right: 0;
      }
    `};
`

const ButtonLink = styled(Link)<{ bubbleType: string }>`
  color: ${({ theme }) => theme.colors.light};
  font-family: ${({ theme }) => theme.font.family.secondary};
  font-size: ${({ theme }) => theme.font.size.xl};
  padding: 3px 20px;
  border-radius: 20px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.light};

    ${(props) =>
      props.bubbleType === 'left' &&
      css`
        color: ${({ theme }) => theme.colors.primary};
      `};

    ${(props) =>
      props.bubbleType === 'right' &&
      css`
        color: ${({ theme }) => theme.colors.secondary};
      `};
  }

  & svg {
    margin-top: -3px;
  }
`

const ButtonArrow = styled(ArrowRight)`
  width: 15px;
  height: 15px;
`

const TextInsideBubble = styled.h2<{ bubbleType: string }>`
  position: absolute;
  top: 45px;
  left: 80px;
  color: ${({ theme }) => theme.colors.light};
  max-width: 300px;
  min-height: 100px;
  display: flex;
  align-items: center;
  line-height: 1;

  @media screen and (max-height: 790px) {
    font-size: 50px;
  }

  @media screen and (min-width: 1400px) {
    font-size: 50px;
  }

  @media screen and (max-width: 1399px) {
    font-size: 35px;
    top: 35px;
    left: 50px;
  }

  @media screen and (max-width: 991px) {
    font-size: 30px;
    max-width: 200px;
    top: 8px;
    left: 30px;
  }

  @media screen and (max-width: 349px) {
    font-size: 26px;
    left: 25px;
    top: 22px;
    max-width: 170px;
  }
`

const IconInsideBubble = styled.img`
  position: absolute;
  right: -30px;
  top: -30px;

  @media (min-width: 1400px) {
    width: 140px;
    height: 140px;
  }

  @media (max-width: 1399px) {
    width: 120px;
    height: 120px;
  }

  @media (max-width: 991px) {
    width: 80px;
    height: 80px;
  }
`

const UspLabel = styled.p`
  font-size: 16px;
  line-height: 30px;
  margin-bottom: 0;
`

const UspText = styled.h2`
  margin-bottom: 0;

  @media (min-width: 1400px) {
    line-height: 35px;
    font-size: 30px;
  }

  @media (max-width: 1399px) {
    line-height: 30px;
    font-size: 24px;
  }

  @media screen and (max-height: 790px) {
    font-size: 20px;
    line-height: 30px;
  }

  @media screen and (max-width: 991px) {
    font-size: 18px;
    line-height: 30px;
  }

  @media screen and (max-width: 576px) {
    font-size: 16px;
    line-height: 20px;
  }
`

const UspIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 992px) {
    width: 80px;
  }

  @media (max-width: 991px) {
    width: 50px;
  }
`

const UspIcon = styled.img`
  @media (min-width: 992px) {
    height: 60px;
    width: 60px;
  }

  @media (max-width: 991px) {
    height: 40px;
    width: 40px;
  }
`

const UspMoreInfo = styled.div`
  font-size: ${({ theme }) => theme.font.size.base};
`

const StyledLink = styled(Link)<{ bubbleType: string }>`
  position: relative;
  border-radius: 35px;
  height: 130px;

  @media (min-width: 992px) {
    border: 5px solid ${({ theme }) => theme.colors.light};
  }

  @media (max-width: 991px) {
    border: 3px solid ${({ theme }) => theme.colors.light};
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.light};

    ${(props) =>
      props.bubbleType === 'right' &&
      css`
        color: ${({ theme }) => theme.colors.secondary} !important;

        @media (min-width: 992px) {
          border: 5px solid ${({ theme }) => theme.colors.secondary};
        }

        @media (max-width: 991px) {
          border: 3px solid ${({ theme }) => theme.colors.secondary};
        }
      `};

    ${(props) =>
      props.bubbleType === 'left' &&
      css`
        color: ${({ theme }) => theme.colors.primary} !important;

        @media (min-width: 992px) {
          border: 5px solid ${({ theme }) => theme.colors.primary};
        }

        @media (max-width: 991px) {
          border: 3px solid ${({ theme }) => theme.colors.primary};
        }
      `};
  }
`

const StyledBackgroundImage = styled(BackgroundImage)`
  filter: brightness(0.7);
`

const Shadow = styled.div<{ type: 'left' | 'right' }>`
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 2;
  opacity: 0.73;

  ${({ type }) =>
    type === 'left'
      ? css`
          background-color: ${({ theme }) => theme.colors.secondary};
        `
      : css`
          background-color: ${({ theme }) => theme.colors.primary};
        `}
`

export type Links = GatsbyTypes.Maybe<
  readonly GatsbyTypes.Maybe<{
    readonly link: GatsbyTypes.Maybe<
      // eslint-disable-next-line
      Pick<GatsbyTypes.WpACF_Link, 'title' | 'url'>
    >
  }>[]
>

interface Props {
  bubbleType: 'left' | 'right'
  background?: any
  title?: string
  icon: any
  image?: any
  usps?: Links
  link?: {
    title?: string
    url?: string
  }
}

function Column({
  background,
  bubbleType,
  title,
  icon,
  image,
  usps,
  link,
}: Props) {
  return (
    <ColumnContainer className="h-100 w-100 d-flex flex-column justify-content-start">
      <StyledBackgroundImage loading="eager" image={background} />
      <Shadow type={bubbleType} />

      <div className="mt-sm-4 px-lg-4 position-relative z-index-2">
        <TextBubble className="position-relative">
          {bubbleType === 'left' ? (
            <ChatCloudLeft className="w-100 h-100" />
          ) : (
            <ChatCloudRight className="w-100 h-100" />
          )}
          <TextInsideBubble bubbleType={bubbleType}>{title}</TextInsideBubble>
          <IconInsideBubble src={icon.localFile.publicURL} alt={title || ''} />
        </TextBubble>
        {link?.url && (
          <ButtonOutsideBubble
            bubbleType={bubbleType}
            className="w-100 d-flex mt-lg-0 mt-5"
          >
            <ButtonLink bubbleType={bubbleType} to={link.url}>
              {link.title}
              <ButtonArrow className="ml-2" />
            </ButtonLink>
          </ButtonOutsideBubble>
        )}
      </div>

      <div
        className={`row no-gutters h-100 position-relative mt-5 mt-md-0 z-index-2 d-flex justify-content-end flex-column${
          bubbleType === 'left' ? ' align-items-end' : ''
        }`}
      >
        <div
          className={`pt-lg-4 pt-1 d-flex flex-column justify-content-center justify-content-lg-end ${
            bubbleType === 'left'
              ? `col-lg-11 align-items-end pl-lg-5`
              : `col-lg-11 align-items-start pr-lg-5`
          } pb-lg-5 pb-3`}
        >
          {usps &&
            usps.map((usp: any) => (
              <StyledLink
                bubbleType={bubbleType}
                to={usp.link.url}
                key={usp.link.title}
                className="text-white w-100 font-family-secondary font-size-3xl mb-lg-4 mb-3 p-3 line-height-none d-flex align-items-center w-100"
              >
                {usp.icon && (
                  <UspIconWrapper>
                    <UspIcon src={usp.icon.localFile.publicURL} />
                  </UspIconWrapper>
                )}
                <div className="px-3 flex-grow-1">
                  {usp.subtitle && <UspLabel>{usp.subtitle}</UspLabel>}
                  <UspText>{parse(usp.link.title)}</UspText>
                  <div className="d-flex align-items-center justify-content-end mt-1">
                    <UspMoreInfo className="d-flex align-items-center">
                      Meer info
                      <ButtonArrow className="ml-3" />
                    </UspMoreInfo>
                  </div>
                </div>
              </StyledLink>
            ))}

          {/* {link?.url && (
            <Button
              arrow
              className={`${
                bubbleType === 'left'
                  ? 'secondary ml-0 mr-lg-0 mr-auto ml-lg-auto'
                  : 'primary mr-auto'
              } filled-white font-size-xl mt-4`}
              to={link.url}
            >
              {link.title}
            </Button>
          )} */}
        </div>

        {/* <div className="col-lg-6 pt-4 d-none flex-column justify-content-end align-items-end pl-5 pb-sm-5 pb-4">
          {link?.url && (
            <Button
              arrow
              className={`${
                bubbleType === 'left' ? 'secondary ml-auto' : 'primary ml-auto'
              } filled-white font-size-xl mt-4`}
              to={link.url}
            >
              {link.title}
            </Button>
          )}
        </div> */}

        {bubbleType === 'right' && (
          <div className="col-lg-3 d-lg-flex d-none align-items-end">
            <Image loading="eager" className="w-100" image={image} alt="Foto" />
          </div>
        )}
      </div>
    </ColumnContainer>
  )
}

export default Column
